input[type='file'] {
  color: transparent;
}

body{
    overflow-x: hidden;
}
.tooltip {
    position: relative;
}
.tooltip-content {
    display: none;
}
.tooltip:hover .tooltip-content {
    display: block;
    width: 120px;
    background-color: #fff;
    position: absolute;
    bottom: 42px;
    color: #000;
    z-index: 99;
    font-size: 12px;
    padding: 4px;
    text-align: center;
    border-radius: 4px;
    transform: translateX(-48px);
}

.tooltip-content:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -5px;
}